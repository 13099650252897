<template>
  <section class="MyProfile-View">
    <Section>
      <section class="profile-details__block">
        <Headline size="4">Profile Details:</Headline>
        <InputLayout label="Account status">
          <span class="profile-details__block-text profile-details__capitalize">{{ getFromRequest('accountStatus').toLowerCase() }}</span>
        </InputLayout>
        <InputLayout label="Company">
          <span class="profile-details__block-text profile-details__capitalize">{{ getFromRequest('company') }}</span>
        </InputLayout>
      </section>

      <section class="profile-details__block">
        <Headline size="4">Personal Information</Headline>
        <InputLayout label="First Name">
          <span class="profile-details__block-text profile-details__capitalize">{{ getFromRequest('firstName') }}</span>
        </InputLayout>
        <InputLayout label="Last Name">
          <span class="profile-details__block-text profile-details__capitalize">{{ getFromRequest('lastName') }}</span>
        </InputLayout>
        <InputLayout label="Date of Birth">
          <span class="profile-details__block-text">{{ (getFromRequest('birthDate') != '--') ? moment(getFromRequest('birthDate')).format('DD.MM.YYYY') : '--' }}</span>
        </InputLayout>
        <InputLayout label="Email">
          <span class="profile-details__block-text">{{ getFromRequest('email') }}</span>
        </InputLayout>
        <InputLayout label="Mobile">
          <span class="profile-details__block-text">{{ getFromRequest('phone') }}</span>
        </InputLayout>
      </section>

      <section class="profile-details__block">
        <Headline size="4">Identification Information</Headline>
        <InputLayout label="Nationality">
          <span class="profile-details__block-text profile-details__capitalize">{{ getFromRequest('person.nationality.name') }}</span>
        </InputLayout>
        <InputLayout label="Gender">
          <span class="profile-details__block-text profile-details__capitalize">{{ getFromRequest('person.gender') }}</span>
        </InputLayout>
        <InputLayout label="Passport number">
          <span class="profile-details__block-text">{{ getFromRequest('person.passportNumber') }}</span>
        </InputLayout>
        <InputLayout label="Passport expiration date">
          <span class="profile-details__block-text">{{ getFromRequest('person.passportExpiryDate') !== '--' ? moment(getFromRequest('person.passportExpiryDate')).format('DD.MM.YYYY') : '--' }}</span>
        </InputLayout>
      </section>

      <section class="profile-details__block">
        <Headline size="4">Organisation Information</Headline>
        <div v-for="(userOrganizationRole, index) in getFromRequest('userOrganizationRoles')"
             :key="index">
          <InputLayout :label="'Organisation'">
            <span class="profile-details__block-text profile-details__capitalize">{{ userOrganizationRole.organization ? userOrganizationRole.organization.title : '--' }}</span>
          </InputLayout>
          <InputLayout label="Role">
            <span class="profile-details__block-text profile-details__capitalize">{{ userOrganizationRole.role ? userOrganizationRole.role.name : '--' }}</span>
          </InputLayout>
          <InputLayout label="Competitions">
            <span v-if="(!!userOrganizationRole.competitions && userOrganizationRole.competitions.length == 0)" class="profile-details__block-text profile-details__capitalize">--</span>
              <span class="profile-details__block-text profile-details__capitalize"
                    v-for="(competition, index) in userOrganizationRole.competitions"
              >{{ (index != userOrganizationRole.competitions.length - 1) ? competition.title + ', ' : competition.title}}</span>
          </InputLayout>
        </div>
      </section>

      <section class="profile-details__block">
        <Headline size="4">Actions' Dates</Headline>
        <InputLayout label="Created at">
          <span class="profile-details__block-text">{{ moment(getFromRequest('created_at')).format('DD.MM.YYYY hh:mm') }}</span>
        </InputLayout>
        <InputLayout label="Updated at">
          <span class="profile-details__block-text">{{ moment(getFromRequest('updated_at')).format('DD.MM.YYYY hh:mm') }}</span>
        </InputLayout>
      </section>
    </Section>
  </section>
</template>

<script>
import Headline from "@/components/Headline/Headline.component";
import get from "lodash.get";
import moment from "moment";
import InputLayout from '@/components/Inputs/components/InputLayout.component';
import Section from '@/components/Section/Section.component';

export default {
  components: {Headline, InputLayout, Section},
  data: () => ({}),
  computed: {
    profileDetails() {
      return get(this, '$store.state.profile.profileDetails')
    },
  },
  methods: {
    moment,
    getFromRequest(path) {
      const data = get(this, `profileDetails.${path}`,)
      return data ? data : '--'
    },
    getProfileDetails() {
      this.$store.dispatch('profile/getProfileDetails')
    },
  },
  created() {
    this.getProfileDetails()
  }
}
</script>

<style lang="scss">
@import "../styles/mixins/ellipsis.mixin";

.profile-details {
  &__block {
    margin-bottom: 50px;
  }

  &__section,
  &__comments {
    margin-top: 60px;
  }

  &__capitalize {
    text-transform: capitalize;
  }
}

.InputLayout {
  margin-bottom: 10px;
}

.headline-component {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.InputLayout__label {
  font-weight: 400;
  font-size: 14px;
  width: 250px;
}
</style>
